import {
  Box,
  Button,
  Card,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import axios from "axios";

import { API_BASE, currentUser } from "@/App";
import { setCurrentUser } from "@/utils";

export default function ArtistAgreement() {
  const [html, setHtml] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch("/NotebookArtistAgreement.html")
      .then((response) => response.text())
      .then((data) => {
        let modifiedHtml = data.replace(
          "EFFECTIVE_DATE",
          new Date().toDateString()
        );
        setHtml(modifiedHtml);
      })
      .catch((error) => {
        console.error("Error fetching:", error);
      });
  }, []); // Only fetch the HTML once

  const handleSubmit = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/artist/publish";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      name: name,
      address: address,
      email: email,
    });
    setCurrentUser(response.data.user);
    await new Promise((r) => setTimeout(r, 1000));
    window.location.href = "/";
  };

  return (
    <Box>
      <div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>

      <Divider />
      <Box sx={{ p: 4, borderRadius: "20px" }}>
        <Typography sx={{ mb: 2 }} variant="body2">
          Click ACCEPT as your signature to agree to all of the terms of this
          agreement for your artist-specific subscription service, including
          without limitation the rights you are granting, your commitments and
          obligations, and the financial terms.
        </Typography>
        <TextField
          fullWidth
          required
          label="Artist/Loanout Company Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ my: 2 }}
        />

        <TextField
          fullWidth
          label="Address (city, state, and zip code)"
          required
          // helperText="e.g. Los Angeles, CA 90001"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          sx={{ my: 2 }}
        />

        <TextField
          fullWidth
          label="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ my: 2 }}
        />

        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          disabled={!name || !address || !email || loading}
          // startIcon={<Verified />}
          sx={{
            borderRadius: "30px",
            mt: 2,
          }}
        >
          Accept
        </Button>
      </Box>
    </Box>
  );
}
