import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box } from "@mui/material";

import { API_BASE, artistKey, currentUser } from "@/App";
import AppLogo from "@/assets/app-logo.svg";
import BottomTabs from "@/components/BottomTabs";
import LiveBanner from "@/components/LiveBanner";
import MainAppBar from "@/components/MainAppBar";
import { MusicContext } from "@/contexts/MusicContext";
import FooterLinks from "@/fragments/FooterLinks";
import usePullToRefresh from "@/hooks/usePullToRefresh";
import { setArtistKey } from "@/utils";
import ArtistHeader from "./ArtistHeader";
import ExperiencesContainer from "./ExperiencesContainer";
import MediaContainer from "./MediaContainer";
import MerchContainer from "./MerchContainer";
import MusicContainer from "./MusicContainer";
import NotesContainer from "./NotesContainer";
import ThreadsContainer from "./ThreadsContainer";

import NewNote from "@/components/NewNote";

export default function ArtistVaultContainer() {
  const navigate = useNavigate();
  const { subscribed, setSubscribed } = useContext(MusicContext);

  const { key } = useParams();

  useEffect(() => {
    if (key) {
      console.log("Setting Artist Key from params:", key);
      // consider checking if key is valid here as well
      setArtistKey(key);
      window.location.href = "/";
    }
  }, [key]);

  const [loading, setLoading] = useState(true);
  const [artist, setArtist] = useState(null);

  const [notes, setNotes] = useState([]);
  const [media, setMedia] = useState([]);
  const [threads, setThreads] = useState([]);
  const [merch, setMerch] = useState([]);
  const [experiences, setExperiences] = useState([]);

  const fetchNotes = async () => {
    const endpoint = API_BASE + "/notes";
    const response = await axios.get(endpoint, {
      params: {
        user_key: currentUser.key,
        artist_key: artistKey,
      },
    });
    setNotes(response.data.content);
  };

  const fetchMedia = async () => {
    const endpoint = API_BASE + "/media";
    const response = await axios.get(endpoint, {
      params: {
        user_key: currentUser.key,
        artist_key: artistKey,
      },
    });
    setMedia(response.data.content);
  };

  const fetchThreads = async () => {
    const endpoint = API_BASE + "/threads";
    const response = await axios.get(endpoint, {
      params: {
        user_key: currentUser.key,
        artist_key: artistKey,
      },
    });
    setThreads(response.data.content);
  };

  const fetchMerch = async () => {
    const endpoint = API_BASE + "/merch";
    const response = await axios.get(endpoint, {
      params: {
        user_key: currentUser.key,
        artist_key: artistKey,
      },
    });
    setMerch(response.data.content);
  };

  const fetchExperiences = async () => {
    const endpoint = API_BASE + "/experiences";
    const response = await axios.get(endpoint, {
      params: {
        user_key: currentUser.key,
        artist_key: artistKey,
      },
    });
    setExperiences(response.data.content);
  };

  useEffect(() => {
    if (!subscribed) return;
    fetchMedia();
    fetchNotes();
    fetchThreads();
    fetchMerch();
    fetchExperiences();
  }, [subscribed]);

  useEffect(() => {
    const checkSubscribed = async () => {
      const endpoint = API_BASE + "/subscribed";
      const response = await axios.get(endpoint, {
        params: {
          user_key: currentUser.key,
          artist_key: artistKey,
        },
      });
      const isSubscribed = response.data.subscribed;
      const isLimited = response.data.limited;

      console.log("Subscribed:", isSubscribed);
      console.log("Limited:", isLimited);

      // save limited to local storage
      localStorage.setItem("limited", isLimited);

      if (isSubscribed) {
        setSubscribed(true);
        setLoading(false);
      } else {
        navigate("/subscribe");
        // if not a valid artistKey, redirect to home
      }
    };
    if (currentUser && artistKey) {
      checkSubscribed();
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchArtist = async () => {
      const endpoint = `${API_BASE}/artist`; // Adjusted to match the new Flask route
      try {
        const response = await axios.get(endpoint, {
          params: { artist_key: artistKey },
        });
        setArtist(response.data.artist); // write this to local? hmmmm
        setLoading(false);
      } catch (error) {
        console.error("Error fetching artist vault:", error);
        // Optionally, handle error (e.g., set an error state, show a message to the user)
      }
    };
    fetchArtist();
  }, []);

  const isPulling = usePullToRefresh(() => {
    return Promise.all([
      // fetchNotes(),
      // fetchMedia(),
      // fetchThreads(),
      // fetchMerch(),
      // fetchExperiences(),
      window.location.reload(),
    ]);
  });

  return (
    <Box sx={{ mt: 0, pb: 4 }}>
      {/* <ApproveArtistAppBar artist={artist} /> */}
      <LiveBanner />
      <MainAppBar loading={isPulling} />

      <ArtistHeader artist={artist} />

      <Box id="main-content" sx={{ minHeight: "80vh" }}>
        <NotesContainer artistContent={notes} />

        <MusicContainer />

        <MediaContainer artistContent={media} />

        <ThreadsContainer artistContent={threads} />

        <Box id="more">
          {merch.length > 0 && <MerchContainer artistContent={merch} />}
          {experiences.length > 0 && (
            <ExperiencesContainer artistContent={experiences} />
          )}
        </Box>
      </Box>
      <PageFooter />
      <BottomTabs />
    </Box>
  );
}

function PageFooter() {
  return (
    <Box
      id="app-logo-container"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        marginBottom: "100px",
      }}
      sx={{ my: 4 }}
    >
      <img
        src={AppLogo}
        alt="notebook-logo"
        style={{ width: 200, opacity: 0.95 }}
        sx={{ mb: 2 }}
      />
      <FooterLinks />
    </Box>
  );
}
