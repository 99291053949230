import React from "react";

import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Skeleton from "@mui/material/Skeleton";

import InstagramIcon from "@mui/icons-material/Instagram";
import IconButton from "@mui/material/IconButton";

import CommentSkeleton from "../../fragments/CommentSkeleton";
import SpotifyIcon from "../../fragments/SpotifyIcon";

import { Edit } from "@mui/icons-material";

import CoverDefault from "@/assets/cover-default.jpg";

import { currentUser } from "@/App";

export default function ArtistHeader({ artist }) {
  return (
    <>
      {!artist ? (
        <>
          <Skeleton variant="rectangular" width="100%" height={400} />
          <Box sx={{ my: 4, px: 2 }}>
            <CommentSkeleton />
            <CommentSkeleton />
            <CommentSkeleton />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            position: "relative",
            display: "inline-block",
            overflow: "hidden",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: "100%", // Full width
              objectFit: "cover", // Maintains aspect ratio while covering the container
              backgroundPosition: "center",
              aspectRatio: "1 / 1",
            }}
            // style={{opacity: artist.meta.cover ? 0.5 : 0.5}}
            image={artist.meta.cover || CoverDefault}
          />

          {currentUser.key === artist.key && (
            <IconButton
              sx={{
                position: "absolute",
                bottom: 8,
                left: 8,
                opacity: 0.8,
                zIndex: 1000,
              }}
              onClick={() => (window.location.href = "/profile")}
            >
              <Edit />
            </IconButton>
            // <Button
            //   sx={{
            //     position: "absolute",
            //     bottom: 20,
            //     left: 20,
            //     zIndex: 1000,
            //     borderRadius: "20px",
            //     // backgroundColor: "rgba(19, 19, 19, 0.2)",
            //   }}
            //   startIcon={<Edit color="secondary"/>}
            //   variant="text"
            //   color="inherit"
            //   size="small"
            //   onClick={() => (window.location.href = "/profile")}
            // >
            //   Edit Cover
            // </Button>
          )}

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%", // Spans entire width
              px: 2,
              pb: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box id="icon-links">
              {artist?.meta.instagram && (
                <IconButton
                  component="a"
                  href={artist?.meta.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "5px" }}
                >
                  <InstagramIcon />
                </IconButton>
              )}

              {artist?.meta.spotify && (
                <IconButton
                  component="a"
                  href={artist?.meta.spotify}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SpotifyIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
