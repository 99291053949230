import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Box,
  LinearProgress,
  TextField,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";

import { ArrowBackIos, ArrowUpward } from "@mui/icons-material";

import { API_BASE, authHeaders, currentUser } from "@/App";

export default function NewNote() {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "note");
    formData.append("meta", JSON.stringify({ text: text }));
    try {
      setLoading(true);
      const endpoint = API_BASE + "/v1/post";
      const response = await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = loading || !text;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        my: 2,
      }}
    >
      <Box style={{ display: "flex", alignItems: "flex-start" }}>
        <Avatar
          src={currentUser.meta.profile}
          sx={{ width: 40, height: 40 }}
          style={{
            border: 1,
            borderColor: "#04FFFF",
            borderStyle: "solid",
            marginTop: "4px",
          }}
          onClick={() => navigate("/profile")}
        />
        <TextField
          fullWidth
          multiline
          minRows={2}
          // size="small"
          sx={{ mx: 2 }}
          autoComplete="off"
          placeholder="Share a note with your fans..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          size="small"
          InputProps={{
            style: {
              borderRadius: "25px",
            },
          }}
          onKeyDown={(e) => {
            if (e.ctrlKey && e.key === "Enter") {
              handleSubmit(e);
            }
          }}
        />
        <IconButton
          // size="small"
          style={{
            background: isDisabled ? "rgba(256,256,256,0.1)" : "#9A5AEF",
          }}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          <ArrowUpward />
        </IconButton>
      </Box>
    </Box>
  );
}
