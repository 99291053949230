import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";

import { ArrowBackIos, ArrowForward } from "@mui/icons-material";

import ArtistLogo from "@/fragments/ArtistLogo";

import ArtistAgreement from "./Agreement";
import NotebookIcon from "@/fragments/NotebookIcon";

export default function ArtistPublish() {
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  return (
    <Box>
      <AppBar
        position="sticky"
        elevation={0}
        style={{ background: "rgba(19,19,19,.9)" }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIos />
          </IconButton>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={tabValue}
              onChange={(e, value) => setTabValue(value)}
              variant="fullWidth"
            >
              <Tab label="Summary" />
              <Tab
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <NotebookIcon size={14} /> 
                    <Box >Agreement</Box>
                  </Box>
                }
                style={{ color: tabValue === 1 ? "#04FFFF" : "inherit" }}
              />
            </Tabs>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Box sx={{ width: "100%" }}>
        <Tabs
          value={tabValue}
          onChange={(e, value) => setTabValue(value)}
          variant="fullWidth"
          position="sticky"
        >
          <Tab label="Summary" />
          <Tab label="Agreement" />
        </Tabs>
      </Box> */}

      <Box sx={{ p: 2 }}>
        {tabValue === 0 && <AgreementSummary setTabValue={setTabValue} />}
        {tabValue === 1 && <ArtistAgreement />}
      </Box>
    </Box>
  );
}

function AgreementSummary({ setTabValue }) {
  return (
    <Box>
      <Typography variant="h6">Artist Agreement Summary</Typography>
      <Typography
        variant="body1"
        sx={{ my: 2, fontStyle: "italic" }}
        color="secondary"
      >
        This summary is a work in progress. Make sure the read the agreement in
        full before signing. Questions? Reach out to{" "}
        <a href="mailto:artists@notebook.fm" style={{ color: "#04FFFF" }}>
          artists@notebook.fm
        </a>
        .
      </Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        Under the Notebook Artist Agreement, you grant Notebook FM a
        non-exclusive license to stream your sound recordings, videos, and other
        content (“Artist Content”) via their Platform and Service. While you
        retain ownership of your content, you agree that during the term of the
        agreement—which is initially two years and automatically renews
        annually—you will not offer a subscription service with exclusive
        content on any other platform, making Notebook’s Service your sole
        artist-specific subscription offering during this period. You are
        responsible for obtaining and paying for all necessary rights, licenses,
        permissions, and consents from third parties (e.g., songwriters,
        publishers, performers) to allow Notebook to use your content as
        outlined.
      </Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        In terms of payment, you will receive 55% of Net Revenues from your
        sound recordings streamed via the Service. Net Revenues are calculated
        as Gross Revenues (all subscription payments received) minus a 20%
        Operations Fee retained by Notebook. After deducting payments for
        publishing rights, payment processing fees, and hosting costs, you will
        receive the remaining Net Revenues for your participation in the
        Platform and provision of other content and user benefits. Royalties are
        paid quarterly via ACH deposit, and you have the right to audit
        Notebook’s records once per year to verify payments. Notebook is not
        obligated to pay additional amounts beyond those specified, and you are
        responsible for your own taxes related to payments received.
      </Typography>
      <Box
        sx={{
          position: "fixed", // Use fixed positioning to always keep it at the bottom
          bottom: 0,
          left: 0,
          right: 0,
          background: "rgba(19,19,19,.9)",
          py: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2,
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setTabValue(1)}
          sx={{ borderRadius: 20, width: "400px" }}
          endIcon={<ArrowForward />}
        >
          Review and Sign Agreement
        </Button>
      </Box>
    </Box>
  );
}

function PageAppBar() {
  const navigate = useNavigate();
  return (
    <AppBar
      position="static"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIos />
        </IconButton>
        <ArtistLogo />
        <Box style={{ width: "40px" }} />
      </Toolbar>
    </AppBar>
  );
}
