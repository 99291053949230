import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Drawer,
  Toolbar,
  Typography,
} from "@mui/material";

import { ExpandMore } from "@mui/icons-material";

import AppIcon from "../assets/app-icon.svg";
import CurrentUserDrawer from "../components/CurrentUserDrawer";

import ArtistLogo from "../fragments/ArtistLogo";

import { API_BASE, artistKey, currentUser } from "../App";
import NotebookIcon from "../fragments/NotebookIcon";

export default function MainAppBar({ loading }) {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        style={{ background: "rgba(19,19,19,.9)" }}
      >
        {loading && (
          <Box sx={{ textAlign: "center", py: 2 }}>
            <CircularProgress size={34} />
          </Box>
        )}
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            onClick={() => setOpen(true)}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <ArtistLogo />
            <ExpandMore sx={{ ml: 0.5, opacity: 0.9 }} />
          </Box>

          {currentUser.role === "artist" && !currentUser.public && !currentUser.verified && (
            <Chip
              label="not verified"
              size="small"
              variant="outlined"
              color="info"
              sx={{ ml: 2 }}
              onClick={() => navigate("/publish")}
            />
          )}

          {currentUser.role === "artist" && !currentUser.verified && currentUser.public && (
            <Chip
              label="In Review"
              size="small"
              variant="outlined"
              color="secondary"
              sx={{ ml: 2 }}
              onClick={() => navigate("/publish")}
            />
          )}

          <CurrentUserDrawer />
        </Toolbar>
      </AppBar>
      <MenuDrawer open={open} setOpen={setOpen} />
    </>
  );
}

function MenuDrawer({ open, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [artists, setArtists] = useState([]); // list[artist]
  useEffect(() => {
    const fetchArtists = async () => {
      setLoading(true);
      const endpoint = `${API_BASE}/active_artists`;
      const response = await axios.get(endpoint, {
        params: {
          user_key: currentUser.key,
        },
      });
      const sortedArtists = response.data.artists.sort((a, b) => {
        if (a.key === artistKey) return -1;
        if (b.key === artistKey) return 1;
        return 0;
      });
      setArtists(sortedArtists);
      setLoading(false);
    };
    fetchArtists();
  }, []);
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: { borderRadius: "10px 10px 0 0" },
      }}
      elevation={10}
    >
      <Box sx={{ px: 2, py: 4 }}>
        <Box id="artists-gallery">
          {/* {currentUser.role === "artist" && <ArtistCard artist={currentUser} />} */}
          {artists?.map((artist, index) => (
            <ArtistCard artist={artist} key={index} />
          ))}
        </Box>
        <Button
          variant="outlined"
          color="white"
          onClick={() => (window.location.href = "/subscriptions")}
          sx={{ borderRadius: "20px", mt: 4, opacity: 0.8 }}
          fullWidth
        >
          Manage Notebooks
        </Button>
      </Box>
    </Drawer>
  );
}

function ArtistCard({ artist }) {
  const navigate = useNavigate();
  return (
    <Card
      key={artist.key}
      sx={{
        display: "flex",
        width: "100%",
        my: 1,
        height: "60px",
        borderRadius: "10px",
      }}
      onClick={() => navigate(`/${artist.key}`)}
    >
      <Box>
        <img
          src={artist.meta?.profile || AppIcon}
          onClick={() => setOpen(true)}
          style={{ width: "60px", height: "60px", objectFit: "cover" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          pl: 2,
          pr: 4,
        }}
      >
        <Typography variant="h7" sx={{ fontWeight: 600 }}>
          {artist.name || artist.key}
        </Typography>
        {artist.key === artistKey && <NotebookIcon size={24} />}
      </Box>
    </Card>
  );
}
