import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import AppLogo from "../../assets/app-logo.svg";

import FrontRowContainer from "./FrontRowContainer";
import ListenerContainer from "./ListenerContainer";

import PaidAudience from "./PaidAudience";
import AgeContainer from "./AgeContainer";
import LocationsContainer from "./LocationsContainer";
import GenderContainer from "./GenderContainer";

import { Verified } from "@mui/icons-material";

import { currentUser } from "../../App";

export default function ArtistData() {
  return (
    <Box>
      <PageAppBar />
      <Box>
        <FrontRowContainer />
        <PaidAudience />

        <ListenerContainer />

        <Box sx={{ py: 4, background: "#191A1A" }}>
          <Typography
            variant="h6"
            // color="#72CCFF"
            color="primary"
            sx={{ m: 2 }}
            style={{ fontWeight: "bold" }}
            align="center"
          >
            Audience Demographics
          </Typography>

          <LocationsContainer />
          <Box sx={{ p: 2 }}>
            <GenderContainer />
            <AgeContainer />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          px: 2,
          pb: 20,
          background:
            "linear-gradient(180deg, rgba(18,18,18,0) 0%, rgba(36,36,36,1) 100%)",
        }}
      >
        <Box
          id="app-logo-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          sx={{ my: 10 }}
        >
          <img src={AppLogo} alt="notebook-logo" style={{ width: 250 }} />
        </Box>
      </Box>
    </Box>
  );
}

function PageAppBar() {
  const navigate = useNavigate();
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => navigate(-1)} sx={{ pl: 0 }}>
          <ArrowBackIosIcon />
        </IconButton>
        {currentUser.role === "artist" && (
          <Box
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => gotoArtist(currentUser.key)}
          >
            <Verified
              color={currentUser.verified ? "secondary" : "disabled"}
              sx={{ mr: 1 }}
            />
            <Typography variant="h6">{currentUser.key}</Typography>
          </Box>
        )}
        <Box style={{ width: "40px" }} />
      </Toolbar>
    </AppBar>
  );
}
