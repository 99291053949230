import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";

import NoteCard from "../Notes/NoteCard";

import NewNote from "@/components/NewNote";
import { currentUser } from "@/App";

export default function NotesContainer({ artistContent }) {
  const navigate = useNavigate();

  return (
    <>
      <Box
        id="notes"
        sx={{
          background:
            "linear-gradient(180deg, rgba(36,36,36,1) 0%, rgba(18,18,18,0) 50%)",
          py: 4,
          px: 2,
        }}
        style={{ marginTop: "-10px" }}
      >
        {artistContent?.length >= 0 && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              sx={{ mt: 2, mb: 2, fontWeight: 800 }}
              // color="secondary"
            >
              Artist Notes
            </Typography>
            {!currentUser.role === "artist" && (
              <IconButton onClick={() => navigate("/notes")}>
                <ArrowForwardIos />
              </IconButton>
            )}
          </Box>
        )}

        <NewNote />

        {artistContent?.slice(0, 3).map((content, index) => (
          <Box key={index}>
            <NoteCard content={content} />
          </Box>
        ))}

        {artistContent?.length > 3 && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => navigate("/notes")}
          >
            <Typography variant="body1" color="darkgray">
              {`View ${artistContent.length - 3} more notes`}
            </Typography>
            <ArrowForwardIos
              style={{ fontSize: "15px", color: "darkgray" }}
              sx={{ ml: 2 }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
